import UserContext from "contexts/UserContext"
import React, { useContext, useState } from "react"
import { useFormik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import iconVisa from '../../assets/iconVisa.svg'
import verifiedbyvisamastercard from '../../assets/verifiedbyvisamastercard.svg'
import verifiedbyvisa from '../../assets/verifiedbyvisa.svg'


interface props{
    isOrangeMaroc:boolean
    isTTMaroc:boolean
    isInwi:boolean
    productMaroc:any
    ProviderCode:string
    setNumCarte:any
    setCodeCVC:any
    setYear:any
    SendValue:any
    FNPayer:any
    functionTracks:any
}

const PaiementMaroc: React.FC<props> = ({
    isOrangeMaroc,
    isTTMaroc,
    isInwi,
    productMaroc,
    ProviderCode,
    setNumCarte,
    setCodeCVC,
    setYear,
    SendValue,
    FNPayer,
    functionTracks
})=>{

    const { user } = useContext(UserContext)
    const [selectedDate, setSelectedDate] = useState(null)
  
    const initialFormData = {
      userId: '',
      SkuCode: '',
      SendValue: '',
      SendCurrencyIso: '',
      AccountNumber: '',
      DistributorRef: '',
      ValidateOnly: '',
      numCard: '',
      inputValue: '',
      code: '',
    }
  
    const validationSchema = yup.object({
      numCard: yup
      .string()
      .matches(/^\d{16}$/, 'Numéro de carte doit contenir exactement 16 chiffres.')
      .test('no-letters', 'Numéro de carte ne doit pas contenir de lettres.', (value:any) => !/[a-zA-Z]/.test(value))
      .required('Numéro de carte est obligatoire.'),
      code: yup
        .string()
        .matches(/^\d{3}$/, 'Numéro de carte doit contenir exactement 3 chiffres.')
        .required('CVC est obligatoire.'),
      inputValue: yup.string().required("La date doit être présentée sous la forme MM/AAAA, avec l'insertion automatique du caractère '/'."),
    })
  
    const handleSubmit = (values: typeof initialFormData) => {
      const id = user?.user?._id
      const token = user?.token?.accessToken
  
      const credentials = {
        userId: user?.user?._id,
        numCard: values.numCard,
        inputValue: values.inputValue,
        code: values.code,
        ValidateOnly: true,
      }
      if (formik.isValid) {
        const productInfo: any = {
          userId: user?.user?._id,
          numCard: values.numCard,
          inputValue: values.inputValue,
          code: values.code,
          ValidateOnly: true,
        }
        console.log('product Info maroc', productInfo)
      } else {
        console.log('Le formulaire contient des erreurs')
      }
    }
    const formik = useFormik({
      initialValues: initialFormData,
      validationSchema: validationSchema,
      onSubmit: handleSubmit,
    })
  
  

    return <div> 
        {ProviderCode === 'OrangeMaroc' ? (
          <div className="">
            <div className={classNames(isOrangeMaroc ? '' : 'hidden')}>
              <form onSubmit={formik.handleSubmit} className="">
                <div className="h-[80px] sm:h-[110px]">
                  <div className="relative mt-3 flex justify-center">
                    <input
                      value={formik.values.numCard}
                      placeholder="Numéro de la carte"
                      name="numCard"
                      onChange={(e: any) => {
                        formik.handleChange(e), setNumCarte(e.target.value)
                      }}
                      onBlur={formik.handleBlur('numCard')}
                      type="text"
                      autoComplete="off"
                      className="p-2 border w-1/2 2xl:w-[400px] sm:w-[300px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                    />
                    <div className="absolute top-0 right-1/4 sm:right-[14%] 2xl:right-[3.75rem] xs:right-[14%] mx-5">
                      <img src={iconVisa} className="h-[55px] w-[55px]" />
                    </div>
                  </div>
                  {formik.errors.numCard && formik.touched.numCard && (
                    <div className="text-red-500 text-sm text-center">{formik.errors.numCard}</div>
                  )}
                </div>

                <div className="h-[80px] sm:h-[110px] w-full">
                  <div className="relative flex justify-center">
                    <DatePicker
                      onBlur={formik.handleBlur('inputValue')}
                      selected={selectedDate}
                      onChange={(date: any, e: any) => {
                         setSelectedDate(date)
                         if (date && !isNaN(date.getTime())) {
                          formik.setFieldValue('inputValue', date)
                          const formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`
                          setYear(formattedDate)
                        }
                        formik.handleChange(e)
                      }}
                      dateFormat="MM/yyyy"
                      showIcon
                      toggleCalendarOnIconClick
                      calendarIconClassname="absolute bottom-3 right-3"
                      showMonthYearPicker
                      placeholderText="MM/AAAA"
                      name="inputValue"
                      autoComplete="off"
                      wrapperClassName="relative w-auto 2xl:w-auto 7xl:w-1/2 5xl:w-1/2"
                      className="p-2 border w-full sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                      calendarClassName="absolute w-full mt-2 border bg-white dark:bg-gray-800 rounded-lg shadow-lg p-2"
                    />
                  </div>
                  {formik.errors.inputValue && formik.touched.inputValue && (
                    <div className="text-red-500 text-sm text-center">{formik.errors.inputValue}</div>
                  )}
                </div>

                <div className="h-[80px] sm:h-[110px]">
                  <div className="w-full flex justify-center">
                    <input
                      value={formik.values.code}
                      name="code"
                      onChange={(e: any) => {
                        formik.handleChange(e), setCodeCVC(e.target.value)
                      }}
                      onBlur={formik.handleBlur('code')}
                      type="text"
                      className="p-2 border w-1/2 sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                      placeholder="CCV"
                      autoComplete="off"
                    />
                  </div>
                  {formik.errors.code && formik.touched.code && (
                    <div className="text-red-500 text-sm text-center">{formik.errors.code}</div>
                  )}
                </div>

                <div className="flex justify-center my-2 items-center ">
                  <button
                    disabled={
                      formik.values.numCard === '' ||
                      formik.values.inputValue === '' ||
                      formik.values.code.length < 3 ||
                      formik.values.code.length > 3 ||
                      formik.values.numCard.length > 16 ||
                      formik.values.numCard.length < 16 ||
                      formik.values.numCard.match(/^[0-9]+$/) === null 
                    }
                    onClick={()=>{
                      FNPayer()
                      functionTracks('3')
                    }}
                    className={classNames(
                      `text-[18px] text-[#FFF] w-1/2 p-2 h-[56px] rounded-xl sm:w-[300px] xs:w-[300px] ${
                        formik.values.numCard === '' ||
                        formik.values.inputValue === '' ||
                        formik.values.code.length < 3 ||
                        formik.values.code.length > 3 ||
                        formik.values.numCard.length > 16 ||
                        formik.values.numCard.length < 16 ||
                        formik.values.numCard.match(/^[0-9]+$/) === null 
                          ? 'bg-gray-300'
                          : 'bg-[#4743C9]'
                      }`,
                    )}
                  >
                    Payer <span className="pl-4 font-semibold">{SendValue} EUR</span>
                  </button>
                </div>

                <div className="text-center text-[15] 2md:text-[15px] 2md:w-[335px] xs:text-[20px] sm:text-[13px] text-normal mx-[60px] sm:mx-[30px] xs:mx-[30px] 2xl:w-[400px] md:mx-0 text-[#6B7280] sm:w-[450px] xs:w-[450px] w-[550px] mt-5">
                  Selon votre établissement bancaire, vous pourrez être redirigé vers la page d&rsquo;authentification
                  de votre banque avant la validation de votre paiement.
                </div>
                <div className="text-center text-[15px] xs:text-[12px] sm:text-[12px] text-normal text-[#6B7280]">
                  <br /> Sécurisé par :
                </div>
                <div className="flex justify-center items-center">
                  <img src={verifiedbyvisamastercard} className="w-32 h-32 mr-5" />
                  <img src={verifiedbyvisa} className="w-32 h-32" />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <>
            {ProviderCode === 'TTMaroc' ? (
              <div className="">
                <div className={classNames(isTTMaroc ? '' : 'hidden')}>
                  <form onSubmit={formik.handleSubmit} className="">
                    <div className="h-[80px] sm:h-[110px]">
                      <div className="relative mt-3 flex justify-center">
                        <input
                          value={formik.values.numCard}
                          placeholder="Numéro de la carte"
                          name="numCard"
                          onChange={(e: any) => {
                            formik.handleChange(e), setNumCarte(e.target.value)
                          }}
                          onBlur={formik.handleBlur('numCard')}
                          type="text"
                          autoComplete="off"
                          className="p-2 border w-1/2 sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                        />
                        <div className="absolute top-0 right-1/4 sm:right-[14%] 2xl:right-[3.75rem] xs:right-[14%] mx-5">
                          <img src={iconVisa} className="h-[55px] w-[55px]" />
                        </div>
                      </div>
                      {formik.errors.numCard && formik.touched.numCard && (
                        <div className="text-red-500 text-sm text-center">{formik.errors.numCard}</div>
                      )}
                    </div>
                    <div className="h-[80px] sm:h-[110px] w-full">
                      <div className="relative flex justify-center">
                        <DatePicker
                          onBlur={formik.handleBlur('inputValue')}
                          selected={selectedDate}
                          onChange={(date: any, e: any) => {
                            setSelectedDate(date)
                            if (date && !isNaN(date.getTime())) {
                              formik.setFieldValue('inputValue', date)
                              const formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}`
                              setYear(formattedDate)
                            }
                            formik.handleChange(e)
                          }}
                          dateFormat="MM/yyyy"
                          showIcon
                          toggleCalendarOnIconClick
                          calendarIconClassname="absolute bottom-3 right-3"
                          showMonthYearPicker
                          placeholderText="MM/AAAA"
                          name="inputValue"
                          autoComplete="off"
                          wrapperClassName="relative w-auto 2xl:w-auto 7xl:w-1/2 5xl:w-1/2"
                          className="p-2 border w-full sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                          calendarClassName="absolute w-full mt-2 border bg-white dark:bg-gray-800 rounded-lg shadow-lg p-2"
                        />
                      </div>
                      {formik.errors.inputValue && formik.touched.inputValue && (
                        <div className="text-red-500 text-sm text-center">{formik.errors.inputValue}</div>
                      )}
                    </div>
                    <div className="h-[80px] sm:h-[110px]">
                      <div className="w-full flex justify-center">
                        <input
                          value={formik.values.code}
                          name="code"
                          onChange={(e: any) => {
                            formik.handleChange(e), setCodeCVC(e.target.value)
                          }}
                          onBlur={formik.handleBlur('code')}
                          type="text"
                          className="p-2 border w-1/2 sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                          placeholder="CCV"
                          autoComplete="off"
                        />
                      </div>
                      {formik.errors.code && formik.touched.code && (
                        <div className="text-red-500 text-sm text-center">{formik.errors.code}</div>
                      )}
                    </div>

                    <div className="flex justify-center my-2 items-center">
                      <button
                        disabled={
                          formik.values.numCard === '' ||
                          formik.values.inputValue === '' ||
                          formik.values.code.length < 3 ||
                          formik.values.code.length > 3 ||
                          formik.values.numCard.length > 16 ||
                          formik.values.numCard.length < 16 ||
                          formik.values.numCard.match(/^[0-9]+$/) === null
                        }
                        onClick={()=>{
                          FNPayer()
                          functionTracks('3')
                        }}
                        className={classNames(
                          `text-[18px] text-[#FFF] w-1/2 p-2 h-[56px] rounded-xl sm:w-[300px] xs:w-[300px] ${
                            formik.values.numCard === '' ||
                            formik.values.inputValue === '' ||
                            formik.values.code.length < 3 ||
                            formik.values.code.length > 3 ||
                            formik.values.numCard.length > 16 ||
                            formik.values.numCard.length < 16 ||
                            formik.values.numCard.match(/^[0-9]+$/) === null
                              ? 'bg-gray-300'
                              : 'bg-[#4743C9]'
                          }`,
                        )}
                      >
                        Payer <span className="pl-4 font-semibold">{SendValue} EUR</span>
                      </button>
                    </div>

                    <div className="text-center text-[15] 2md:text-[15px] 2md:w-[335px] xs:text-[20px] sm:text-[13px] text-normal mx-[60px] sm:mx-[30px] xs:mx-[30px] 2xl:w-[400px] md:mx-0 text-[#6B7280] sm:w-[450px] xs:w-[450px] w-[550px] mt-5">
                      Selon votre établissement bancaire, vous pourrez être redirigé vers la page
                      d&rsquo;authentification de votre banque avant la validation de votre paiement.
                    </div>
                    <div className="text-center text-[15] xs:text-[12px] sm:text-[12px] text-normal text-[#6B7280]">
                      <br /> Sécurisé par :
                    </div>
                    <div className="flex justify-center items-center">
                      <img src={verifiedbyvisamastercard} className="w-32 h-32 mr-5" />
                      <img src={verifiedbyvisa} className="w-32 h-32" />
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <>
                {ProviderCode === 'Inwi' ? (
                  <div className="">
                    <div className={classNames(isInwi ? '' : 'hidden')}>
                      <form onSubmit={formik.handleSubmit} className="">
                        <div className="h-[80px] sm:h-[110px]">
                          <div className="relative mt-3 flex justify-center">
                            <input
                              value={formik.values.numCard}
                              placeholder="Numéro de la carte"
                              name="numCard"
                              onChange={(e: any) => {
                                formik.handleChange(e), setNumCarte(e.target.value)
                              }}
                              onBlur={formik.handleBlur('numCard')}
                              type="text"
                              autoComplete="off"
                              className="p-2 border w-1/2 sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                            />
                            <div className="absolute top-0 right-1/4 2xl:right-[3.75rem] sm:right-[14%] xs:right-[14%] mx-5">
                              <img src={iconVisa} className="h-[55px] w-[55px]" />
                            </div>
                          </div>
                          {formik.errors.numCard && formik.touched.numCard && (
                            <div className="text-red-500 text-sm text-center">{formik.errors.numCard}</div>
                          )}
                        </div>

                        <div className="h-[80px] sm:h-[110px] w-full">
                          <div className="relative flex justify-center">
                            <DatePicker
                              onBlur={formik.handleBlur('inputValue')}
                              selected={selectedDate}
                              onChange={(date: any, e: any) => {
                                setSelectedDate(date)
                                if (date && !isNaN(date.getTime())) {
                                  formik.setFieldValue('inputValue', date)
                                  const formattedDate = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(
                                    -2,
                                  )}`
                                  setYear(formattedDate)
                                }
                                formik.handleChange(e)
                              }}
                              dateFormat="MM/yyyy"
                              showIcon
                              toggleCalendarOnIconClick
                              calendarIconClassname="absolute bottom-3 right-3"
                              showMonthYearPicker
                              placeholderText="MM/AAAA"
                              name="inputValue"
                              autoComplete="off"
                              wrapperClassName="relative w-auto 2xl:w-auto 7xl:w-1/2 5xl:w-1/2"
                              className="p-2 border w-full sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                              calendarClassName="absolute w-full mt-2 border bg-white dark:bg-gray-800 rounded-lg shadow-lg p-2"
                            />
                          </div>
                          {formik.errors.inputValue && formik.touched.inputValue && (
                            <div className="text-red-500 text-sm text-center">{formik.errors.inputValue}</div>
                          )}
                        </div>

                        <div className="h-[80px] sm:h-[110px]">
                          <div className="w-full flex justify-center">
                            <input
                              value={formik.values.code}
                              name="code"
                              onChange={(e: any) => {
                                formik.handleChange(e), setCodeCVC(e.target.value)
                              }}
                              onBlur={formik.handleBlur('code')}
                              type="text"
                              className="p-2 border w-1/2 sm:w-[300px] 2xl:w-[400px] xs:w-[300px] h-[56px] rounded-xl bg-[#F9FAFB] placeholder:text-[#9CA3AF]"
                              placeholder="CCV"
                              autoComplete="off"
                            />
                          </div>
                          {formik.errors.code && formik.touched.code && (
                            <div className="text-red-500 text-sm text-center">{formik.errors.code}</div>
                          )}
                        </div>

                        <div className="flex justify-center my-2 items-center">
                          <button
                            disabled={
                              formik.values.numCard === '' ||
                              formik.values.inputValue === '' ||
                              formik.values.code.length < 3 ||
                              formik.values.code.length > 3 ||
                              formik.values.numCard.length > 16 ||
                              formik.values.numCard.length < 16 ||
                              formik.values.numCard.match(/^[0-9]+$/) === null
                            }
                            onClick={()=>{
                              FNPayer()
                              functionTracks('3')
                            }}
                            className={classNames(
                              `text-[18px] text-[#FFF] w-1/2 p-2 h-[56px] rounded-xl sm:w-[300px] xs:w-[300px] ${
                                formik.values.numCard === '' ||
                                formik.values.inputValue === '' ||
                                formik.values.code.length < 3 ||
                                formik.values.code.length > 3 ||
                                formik.values.numCard.length > 16 ||
                                formik.values.numCard.length < 16 ||
                                formik.values.numCard.match(/^[0-9]+$/) === null
                                  ? 'bg-gray-300'
                                  : 'bg-[#4743C9]'
                              }`,
                            )}
                          >
                            Payer <span className="pl-4 font-semibold">{SendValue} EUR</span>
                          </button>
                        </div>

                        <div className="text-center text-[15] 2md:text-[15px] 2md:w-[335px] xs:text-[20px] sm:text-[13px] text-normal mx-[60px] sm:mx-[30px] xs:mx-[30px] 2xl:w-[400px] md:mx-0 text-[#6B7280] sm:w-[450px] xs:w-[450px] w-[550px] mt-5">
                          Selon votre établissement bancaire, vous pourrez être redirigé vers la page
                          d&rsquo;authentification de votre banque avant la validation de votre paiement.
                        </div>
                        <div className="text-center text-[15] xs:text-[12px] sm:text-[12px] text-normal text-[#6B7280]">
                          <br /> Sécurisé par :
                        </div>
                        <div className="flex justify-center items-center">
                          <img src={verifiedbyvisamastercard} className="w-32 h-32 mr-5" />
                          <img src={verifiedbyvisa} className="w-32 h-32" />
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
         </div>
}
export default PaiementMaroc
